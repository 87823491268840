<template>
    <section class="main-feedback pattern">
        <div class="container">
            <div class="main-feedback__content wow fadeInLeft" data-wow-delay=".5s">
                <h3 class="main-feedback__title">
                    {{$t("mainFeedback.title")}}
                </h3>
                <p v-html="$t('mainFeedback.text')" class="main-feedback__text">
                </p>
            </div>
            <form action="#" class="main-feedback__form wow fadeInRight" data-wow-delay=".5s" @submit.prevent="sendMessage">
                <label for="name">{{$t("form.name")}}</label>
                <input type="text" id="name" required v-model="name">
                <label for="tel">{{$t("form.tel")}}</label>
                <vue-tel-input v-model="code" id="tel" required
                @input="detectCounrty"></vue-tel-input>
                <label for="message">{{$t("form.comment")}}</label>
                <input type="text" id="message" required v-model="message">
                <button type="submit" class="btn" v-if="!sended" :disabled="btnDisabled">
                    {{$t("buttons.feedbackSend")}}
                </button>
                <p class="main-feedback__done" v-else>
                    Ваша заявка отправлена
                </p>
            </form>
        </div>
        <div class="main-feedback__sweets">
            <img src="../assets/img/choco1.png" alt="sweets" v-rellax="sweets">
            <img src="../assets/img/choco2.png" alt="sweets" v-rellax="sweets">
        </div>
    </section>
</template>

<script>
import {feedbackAPI} from '../api'

export default {
    data() {
        return {
            sweets: {
                speed: 4
            },
            sended: false,
            name: '',
            code: '',
            tel: '',
            IP: '',
            detectIP: true,
            country: '',
            message: '',
            btnDisabled: false,
        }
    },
    
    methods: {
        async sendMessage() {
           this.btnDisabled = true
           await feedbackAPI(this.name, '', this.tel, this.country, this.IP, '', this.message, '')
           .then(response => {
               if(response.status == 200 || response.status == 201) {
                    this.name = ''
                    this.tel = ''
                    this.message = ''
                    this.sended = true
               }
           })
       },

       detectCounrty(number, phoneObj) {
           if(this.detectIP) {
              this.IP = phoneObj.country.name 
           }
           this.code = '+' + phoneObj.country.dialCode + ' '
           this.country =  phoneObj.country.name 
           this.tel = number
       }
    },

    watch: {
        IP() {
            this.detectIP = false
        }
    }
}
</script>

<style lang="less">
.main-feedback {
    height: 100vh;
    overflow: visible !important;
    z-index: 1;

    &.pattern::before, &.pattern::after {
        background-image: url(../assets/img/pattern-red.png);
    }


    .container {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
    }

    &__content {
        max-width: 40.5rem;
        margin-right: 9rem;
    }

    &__title {
        color: var(--red);
        font-weight: 700;
        font-size: 3rem;
        margin-bottom: 1.2rem;
    }

    &__text {
        font-size: 1.1rem;
        line-height: 167%;
        color: var(--red);
    }  
    
    &__form {
        border: .2rem solid var(--red);
        border-radius: 1.5rem;
        padding: 2.4rem;
        width: 27rem;
        background: #fff;

        label {
            display: block;
            color: var(--red);
            font-size: 1rem;
            margin-bottom: 0.4rem;
            padding-left: 1rem;
        }

        input {
            background: #efefef;
            display: block;
            width: 100%;
            border: .15rem solid transparent;
            transition: .3s all;
            outline: none;
            color: #fff;
            padding: .85rem 1rem;
            border-radius: 5rem;
            margin-bottom: 1.2rem;
            color: var(--red);

            &:focus {
                border-color: var(--red);
            }
        }

        .vue-tel-input {
            position: relative;
            border: none;
            margin-bottom: 1.2rem;

            &:focus-within {
                border: none;
                box-shadow: none;
            }

            .vti__dropdown {
                position: absolute;
                left: .8rem;
                top: 50%;
                transform: translateY(-50%);

                &:hover {
                    background: transparent;
                }

                &-list.below {
                    background: #fff;
                    color: var(--red);
                    width: 22rem !important;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }

                &-item.highlighted {
                    color: #2f2f2f;
                }
            }

            input {
                margin-bottom: 0;
                padding-left: 4rem;
            }
        }

        .btn {
            width: 100%;
            color: #fff;
            margin-top: 1.5rem;
            background: var(--red);
            border-color: var(--red);

            &:hover {
                color: var(--red);
            }
        }
    }

    &__done {
        color: var(--red);
        text-align: right;
        margin-top: 1.5rem;
        font-size: 1rem;
    }

    &__sweets {
        img {
            position: absolute;
            width: 15rem;
            z-index: 2;

            &:first-child {
                left: 0;
                top: 225vh;
            }

            &:last-child {
                right: 0;
                top: 160vh;
            }
        }
    }

}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .main-feedback {
        height: auto;
        padding: 10rem 0;
        overflow: hidden !important;

        .container {
            display: block;
        }

        &__content {
            text-align: center;
            margin-right: 0;
            margin-bottom: 2rem;
        }

        &__title {
            font-size: 2rem;
        }

        &__text {
            font-size: 1rem;
        }

        &__sweets {
            img {
                z-index: 0;

                &:last-child {
                    top: 107vh;
                }

                &:first-child {
                    display: none;
                }
            }
        }

        &__form {
            width: 100%;
            padding: 2.2rem 1rem;
        }
    }
}
</style>