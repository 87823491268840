<template>
  <div>
    <scrollactive class="main-scroll">
      <a :href="`#${item}`" v-for="(item, idx) in scrollItem" 
       :key="idx" class="scrollactive-item"></a>
    </scrollactive>



    <page-video @changeHeader=changeHeader :title="$t('main.title')" 
    :text="$t('main.text')" id="main" :video="videos[0].video" v-if="videos.length>0"/>
    <div class="pageStock" v-else></div>
    <app-about id="about"/>
    <main-catalog id="catalog"/>
    <app-map id="map"/>
    <main-feedback id="feedback"/>
    <!-- <main-news id="news"/> -->
  </div>
</template>

<script>
import PageVideo from '../components/PageVideo.vue'
import AppAbout from '../components/About.vue'
import AppMap from '../components/Map.vue'
import MainFeedback from '../components/MainFeedback.vue'
import MainCatalog from '../components/MainCatalog.vue'

export default {
  components: {
    PageVideo,
    AppAbout,
    AppMap,
    MainFeedback,
    MainCatalog,
  },

  beforeCreate() {
    window.location.hash = '';
  },

  mounted() {
    this.$options.metaInfo.title = this.$i18n.t('meta.main.title')
    this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.main.description')
    this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.main.keywords')
  },

  data() {
    return {
      scrollItem: ['main', 'about', 'catalog', 'map', 'feedback', 'footer'],
    }
  },

  metaInfo: {
    title: '',
    meta: [
      { name: 'description', content: '' },
      { name: 'keywords', content: '' },
    ]
  },

  methods: {
    changeHeader(data) {
      this.$emit('changeHeader', data)
    }
  },

  computed: {
    videos() {
      return this.$store.getters.getVideos
    }
  }
  
}
</script>
