<template>
    <div class="history-prod">
        <div class="history__title">
            {{$t('history.prod')}}
        </div>
        <div class="history-prod__wrap" ref="prodAnim">
            <div class="history-prod__item">
                <div>
                    11
                </div>
                <p>
                    {{$t('history.prodCrois')}}
                </p>
            </div>
            <transition name="fade">
                <div class="history-prod__item" v-if="prod[0].anim">
                    <div>
                        15
                    </div>
                    <p>
                        {{$t('about.numbers[0]')}}
                    </p>
                </div>
            </transition>
            <transition name="fade">
                <div class="history-prod__item" v-if="prod[1].anim">
                    <div>
                        60
                    </div>
                    <p>
                        {{$t('about.numbers[1]')}}
                    </p>
                </div>
            </transition>
            <transition name="fade">
                <div class="history-prod__item" v-if="prod[2].anim">
                    <div>
                        90
                    </div>
                    <p>
                        {{$t('about.numbers[2]')}}
                    </p>
                </div>
            </transition>
            <transition name="fade">
                <div class="history-prod__item" v-if="prod[3].anim">
                    <div>
                        150.000
                    </div>
                    <p>
                        {{$t('history.prodShop')}}
                    </p>
                </div>
            </transition>    
            <div class="lines">
                <div v-for="item, idx in prod" :key="idx">
                    <span :class="{active: prod[idx].anim}"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        window.addEventListener('scroll', this.showProd)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.showProd)
    },
    data() {
        return {
            prod: [
                {anim: false}, {anim: false}, {anim: false}, {anim: false}],
        }
    },
    methods: {
        showProd() {
            if(this.$refs.prodAnim.getBoundingClientRect().top < window.innerHeight) {
                let i = 0
                setInterval(() => {
                    if(i == 4) {
                        return false
                    } else {
                        this.prod[i].anim = true
                        i++
                    }
                    
                }, 1000)
            }
        }
    }
}
</script>

<style lang="less">
.history-prod {
    margin-bottom: 4rem;

    &__wrap {
        position: relative;
        height: 30rem;

    }

    &__item {
        text-align: center;
        position: absolute;


        div {
             background: -webkit-linear-gradient(0deg, #BD9E3E, var(--yellow));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 4.8rem;
            line-height: 100%;
            font-weight: bold;
            display: inline-block;
        }

        p {
            font-size: 1rem;
            line-height: 133%;
            max-width: 14rem;
            margin: 0 auto;
        }

        &:first-child {
            left: -3rem;
            bottom: 2rem;

            &::before {
                right: 0;
                top: 2rem;
            }

            
        }

        &:nth-child(2) {
            left: 16%;
            top: 5rem;  
        }

        &:nth-child(3) {
            left: 46.5%;
            bottom: 0;  
        }

        &:nth-child(4) {
            right: 0;
            bottom: 40%; 
            
                &::before {
                bottom: 50%;
                transform: translateY(50%);
                left: -2rem;
            }
        }
        

        &:nth-child(5) {
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            div {
                font-size: 4.8rem;
                background: -webkit-linear-gradient(0deg, #BD9E3E, var(--yellow));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            &::before {
                content: none;
            }
        }

    }

    .lines {
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        left: 0;
        bottom: 0;

        div {
            position: absolute;
            

            span {
                display: block;
                height: 0.6rem;
                width: 0%;
                border-bottom: 3px dashed  #D0D0D0;
                transition: .8s all;
                position: relative;
                z-index: -1;
            }

            .active {
                width: 100%;
            }

            &::before, &::after {
                position: absolute;
                background: linear-gradient(90deg, #BD9E3E 0%, #FFE185 100%), #E7B109;
                content: '';
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                position: absolute;
                top: 0;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }

            &:first-child {
                top: 16.2vw;
                left: 8.75vw;
                width: 12vw;
                transform: rotate(-40deg);
            }


            &:nth-child(2) {
                top: 16.85vw;
                left: 18vw;
                width: 30vw;
                transform: rotate(17deg);
            }

            &:nth-child(3) {
                top: 16.9vw;
                left: 45.45vw;
                width: 29.6vw;
                transform: rotate(-17deg)
            }

            &:nth-child(4) {
                top: 9.5vw;
                left: 55.2vw;
                width: 20vw;
                transform: rotate(204deg);

                &::after {
                    content: none;
                }
            }

            &:last-child span::after {
                position: absolute;
                right: -.6rem;
                top: .25rem;
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: .25rem 0 .25rem .5rem ;
                border-color: transparent  transparent transparent #D0D0D0;
            }
            
        }


        
    }
}


            
 
@media (max-width: 1080px) and (orientation: portrait) {
     .history-prod .lines {
         display: none;
     }
 }
 
@media (max-width: 768px) {
    .history-prod {

        &__wrap {
            height: 25rem;
        }

        &__item {
            div {
                font-size: 3rem;
            }

            p {
                font-size: .7rem;
                max-width: 7rem;
            }

            &:first-child {
                left: -1rem;
            }

            &:nth-child(5) div {
                font-size: 5rem;
            }

            &:nth-child(2) {
                top: 10rem;
            }

            &:nth-child(3) {
                left: 45%;
            }
        }

        .lines {

            div {
                
                span {
                    height: 0.4rem;
                    border-bottom: 2px dashed  #D0D0D0;
                }


                &::before, &::after {
                    width: .8rem;
                    height: .8rem;
                }


                &:first-child {
                    top: 61.8vw;
                    left: 17.5vw;
                    width: 12vw;
                    transform: rotate(-30deg);
                }


                &:nth-child(2) {
                    top: 66.6vw;
                    left: 24.4vw;
                    width: 26vw;
                    transform: rotate(38deg);
                }

                &:nth-child(3) {
                    top: 59.4vw;
                    left: 36.5vw;
                    width: 38vw;
                    transform: rotate(-55deg);
                }

                &:nth-child(4) {
                    top: 38.7vw;
                    left: 45.2vw;
                    width: 25vw;
                    transform: rotate(223deg);
                }

                &:last-child span::after {
                    right: -.6rem;
                    top: 0rem;
                }
                
            }
            
        }
    }
}

@media (max-width: 568px) {
    .history-prod .lines {
        display: block;
    }
}

</style>