<template>
    <div>
        <page-video @changeHeader=changeHeader :notMain=true :video="videos[2].video" v-if="videos.length>0"
        :title="$t('news.title')" :text="$t('news.desc')" @openFeedback=openFeedback />
        <section class="news" v-if="news.length > 0">
             <h2 class="catalog__title" v-rellax="title">
                {{$t('news.title')}}
            </h2>
            <div class="container">
                <div class="news-wrap">
                    <news-item v-for="(item, idx) in paginatedNews" :key="idx"
                    :image=item.photo :date=item.created_at :text="getLangName(item)" 
                    :id=item.id />
                </div>
                <div class="news-pagination">
                    <a href="#" class="news-pagination__back" :class="{'disabled': currentPage == 1}" @click.prevent="currentPage--"> 
                        <arrow-left />
                    </a>
                    <ul>
                        <li v-for="(item, idx) in paginatedPage" :key="idx">
                            <a href="#" :class="{current: (idx+1) == currentPage}" v-if="currentPage < 3" @click.prevent="currentPage = (idx+1)">
                                {{idx+1}}
                            </a>
                            <a href="#" :class="{current: idx == paginatedPage-1}" v-else-if="currentPage == totalPage" @click.prevent="currentPage = (idx+(currentPage-2))">
                                {{idx+(currentPage-2)}}
                            </a>
                            <a href="#" :class="{current: idx == (paginatedPage-2)}" v-else @click.prevent="currentPage = (idx+(currentPage-1))">
                                {{idx+(currentPage-1)}}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="disabled dots">
                                ...
                            </a>
                        </li>
                    </ul>
                    <a href="#" class="news-pagination__forward" @click.prevent="currentPage++" :class="{'disabled': !hasNextPage}">
                        <arrow-right />
                    </a>
                </div>
            </div>
            <div class="about-sweets">
                <img src="../assets/img/cookies.png" alt="Cookies" class="about-sweets__cookies" 
                v-rellax="sweets">
                <img src="../assets/img/milk.png" alt="Cookies" class="about-sweets__milk" 
                v-rellax="sweets">
                <img src="../assets/img/waffles-about.png" alt="Waffles" class="about-sweets__waffles" 
                v-rellax="sweets">
            </div>
        </section>
    </div>
</template>

<script>
import PageVideo from '../components/PageVideo.vue'
import NewsItem from '../components/NewsItem.vue'
import ArrowLeft from '../components/ArrowLeft.vue'
import ArrowRight from '../components/ArrowRight.vue'

export default {
    components: {
        NewsItem,
        PageVideo,
        ArrowLeft, ArrowRight
    },
    created() {
        const windowData = Object.fromEntries(
            new URL(window.location).searchParams.entries()
        );

        if (windowData.page) {
            this.currentPage = windowData.page;
        }
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.news.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.news.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.news.keywords')
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            title: {
                speed: 3
            },
            sweets: {
                speed: 4
            },
            pageSize: 8,
            currentPage: 1,
        }
    },
    methods: {
        changeHeader(data) {
            this.$emit('changeHeader', data)
        },
        openFeedback() {
            this.$emit('openFeedback')
        },
        updateCurrentURL() {
            window.history.pushState(null, document.title, `${window.location.pathname}?page=${this.currentPage}`)
        },
        getLangName(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.name_en
            } else {
                return item.name_ru
            }
        }
    },
    computed: {
        paginatedNews() {
            if(this.news.length > this.pageSize) {
                return this.news.slice((this.currentPage-1)*this.pageSize, this.currentPage*this.pageSize)
            } else {
                return this.news
            }
            
        },

        hasNextPage() {
            return this.news.length > this.endPage
        },

        endPage() {
            return this.currentPage*this.pageSize
        },

        totalPage() {
            return Math.ceil(this.news.length/this.pageSize)
        },

        paginatedPage() {
            if(this.totalPage > 3) {
                return 3
            } else {
                return this.totalPage
            }
        },

        news() {
            return this.$store.getters.getNews
        },

        videos() {
            return this.$store.getters.getVideos
        },

    },

    watch: {
        currentPage() {
            this.updateCurrentURL()
            window.scrollTo(0, window.innerHeight)
        }
    }
}
</script>

<style lang="less">
.news {
    padding: 10rem 0 6rem;
    background: #e5e5e5;
    position: relative;

    .about-sweets img {
        width: 10rem;
    }

    .catalog__title {
        margin-top: 0;
        color: #fff;
        text-shadow: 0px .3rem 1rem rgba(120, 120, 120, 0.4);
    }

    &-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    &-pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem;

            li {
                margin: 0 0.3rem;

                a {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background: #fff;
                    border: 1px solid #CFCFCF;
                    color: #000;
                }

                .current {
                    font-weight: bold;
                    color: #df9d01;
                    border-color: #df9d01;
                }

            }
        }

        .disabled {
            pointer-events: none;
            opacity: .5;
        }

        &__back, &__forward {
            svg {
               width: 3.2rem; 
               filter: drop-shadow(4px 7px 16px rgba(0, 0, 0, 0.25));

               .fill {
                    transition: .3s all;
                }

                &:hover {
                    .fill {
                        fill: transparent;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px), (max-width: 1050px) and (orientation: portrait) {
    .news {
        padding: 4rem 0;

        .about-sweets img {
            display: none;
        }
    }
}

</style>

<style scoped>

    .news-item {
        width: 23.5%;
        margin: 0 .75% 4rem; 
    }

    @media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
        .news-item {
            width: 100%;
            margin: 0 0 1rem;
        }
    }

</style>