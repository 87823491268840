<template>
    <section class="waffles">
        <div class="container">
            <swiper ref="swiperGallery" :options="swiperOptions">
                <swiper-slide v-for="(item, idx) in carousel" :key="idx">
                    <div class="gallery-carousel__item" @click="zoomOpen(item.image)">
                        <img :src="require('../assets/img/' + item.image)" alt="gallery-item">
                        <span>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38 10V38H10V10H38ZM38 6H10C7.8 6 6 7.8 6 10V38C6 40.2 7.8 42 10 42H38C40.2 42 42 40.2 42 38V10C42 7.8 40.2 6 38 6ZM28.28 23.72L22.28 31.46L18 26.28L12 34H36L28.28 23.72Z" fill="white"/>
                            </svg>
                        </span>
                    </div>
                </swiper-slide>
            </swiper> 
            <div class="gallery-carousel__arrows">
                <span @click="prevSlide">
                    <arrow-left />
                </span>
                <span @click="nextSlide">
                    <arrow-right />
                </span>
            </div>
        </div>
        <transition name="fade">
            <div class="gallery-zoom" v-if="showZoom" @click.self="showZoom = false">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" @click="showZoom = false">
                    <path d="M11.5237 10.6259L19.8274 18.9295L18.9125 19.8445L10.6088 11.5408L1.92532 20.2243L0.975834 19.2748L9.6593 10.5914L1.68363 2.61569L2.59859 1.70073L10.5743 9.67639L19.275 0.975667L20.2245 1.92515L11.5237 10.6259Z" fill="#fff"></path>
                </svg>
                <img :src="require('../assets/img/' + zoomImg )" alt="img">
            </div>
        </transition>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'

export default {
    components: {
        Swiper,		  
		SwiperSlide,
        ArrowLeft, ArrowRight
    },
    created() {
        setInterval(() => {
            if(this.$refs.swiperGallery) {
                this.$refs.swiperGallery.$swiper.slideNext()
            }		
        }, 500)
    },
    

    data() {
        return {
            carousel: [
                {image: 'waffles-gallery1.jpg',},
                {image: 'waffles-gallery2.jpg',},
                {image: 'waffles-gallery3.jpg',},
                {image: 'waffles-gallery4.jpg',},
                {image: 'waffles-gallery5.jpg',},
                {image: 'waffles-gallery6.jpg',},
                {image: 'waffles-gallery7.jpg',},
            ],
            showZoom: false,
            zoomImg: '',
            swiperOptions: {
                speed: 2500,
                loop: true,
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: "1.3",
                    },

                    550: {
                        slidesPerView: "2",
                        speceBetween: 20,
                    },

                    900: {
                        spaceBetween: 30,
                        slidesPerView: "3",
                    },
                }
            },
        }
    },

    methods: {
        zoomOpen(src) {
            this.showZoom = true
            this.zoomImg = src
        },
        prevSlide() {
            this.$refs.swiperGallery.$swiper.slidePrev()
        },

        nextSlide() {
            this.$refs.swiperGallery.$swiper.slideNext()
        },
    },
}
</script>

<style lang="less">
.waffles {
    margin-top: 6rem;

    &-title {
        text-align: center;
    }

    .gallery-wrap {
        min-height: 25rem;

        &::after {
            background-image: url(../assets/img/waffles-shop.png);
            height: 25rem;
            background-position-x: right;
        }
    }

    .gallery-carousel__item img{
        border: none;
    }
    
}
</style>