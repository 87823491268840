<template>
    <div v-if="products.length > 0">
        <section class="product pattern" :style="{background: color}">
            <div class="container">
                <div class="product__title">
                    {{name}}
                </div>
                <div class="product-content">
                    <p class="product__desc">
                        {{desc}}
                    </p>
                    <img :src="$hostname + image" alt="" class="product__img">
                    <div class="product-info">
                        <div class="product-info__item">
                            <div>{{ $t('catalog.code') }}:</div>
                            <div>{{ packageInfo.code }}</div>
                        </div>
                        <div class="product-info__item">
                            <div>{{ $t('catalog.weight') }}:</div>
                            <div>{{ packageInfo.weight }}</div>
                        </div>
                        <div class="product-info__item" v-if="packageInfo.box">
                            <div>{{ $t('catalog.box') }}:</div>
                            <div>{{ packageInfo.box }}</div>
                        </div>
                        <div class="product-info__item">
                            <div>{{ $t('catalog.life') }}:</div>
                            <div>{{ packageInfo.time }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="product-bg" :style="{'background-image': `url(${$hostname + bg})`}">
        </div>
        <app-package :product=product :brand=brand :comp=comp :packageInfo=packageInfo :packageImg=packageImg 
        :packageIcos=packageIcos @openFeedback=openFeedback />
    </div>
</template>

<script>
import AppPackage from '../components/Package.vue'

export default {
    components: {
        AppPackage
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.products.title')
        this.setProduct()              
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            id: 0,
            name: '',
            desc: '',
            image: '',
            color: '',
            bg: '',
            comp: '',
            product: null,
            brand: null,
            packageImg: '',
            packageInfo: {
                code: null,
                weight: null,
                box: null,
                time: null,
            },
            packageIcos: ['package1.svg','package2.svg','package3.svg','package4.svg','package5.svg',],         
        }
    },
    methods: {
        openFeedback() {
            this.$emit('openFeedback')
        },

        setProduct() {
            if(this.products.length > 0) {
                this.id = this.$route.params.id
                let product = this.products.find(item => item.id == this.id)
                if(product) {
                    this.image = product.photo
                    this.color = product.color
                    this.bg = product.back_photo
                    this.packageImg = product.box_photo
                    this.product = product.cat_id
                    this.brand = product.brand_id
                    this.packageInfo.code = product.type_ru
                    if(this.$root.$i18n.locale == 'uz') {
                        this.desc = product.l_description_uz
                        this.comp = product.composition_uz
                        this.name = product.name_uz
                        this.packageInfo.weight = product.weight_uz
                        this.packageInfo.box = product.number_uz
                        this.packageInfo.time = product.shelflife_uz
                    }  else if(this.$root.$i18n.locale == 'en') {
                        this.desc = product.l_description_en
                        this.comp = product.composition_en
                        this.name = product.name_en
                        this.packageInfo.weight = product.weight_en
                        this.packageInfo.box = product.number_en
                        this.packageInfo.size = product.size_en
                    }  else {
                        this.desc = product.l_description_ru
                        this.comp = product.composition_ru
                        this.name = product.name_ru
                        this.packageInfo.weight = product.weight_ru
                        this.packageInfo.box = product.number_ru
                        this.packageInfo.time = product.shelflife_ru
                    }

                    this.$options.metaInfo.title = 'Deya | ' + this.name
                    this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.products.description')
                    this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.products.keywords')

                } else {
                    this.$router.push('/404')
                }

            }
        },
    },
    computed: {
        products() {
            return this.$store.getters.getProducts
        },
    },

    watch: {
        products() {
            this.setProduct()
        },

        $route() {
            this.setProduct()
        }
    }
}
</script>

<style lang="less">
.product {
    padding-top: 10rem;
    color: rgba(255, 255, 255, .95);
    margin-bottom: -16.5rem;


    &__title {
        font-size: 3rem;
        font-weight: 700;
        margin: 0 auto 1rem;
        width: 36rem;
        text-align: center;
    }

    &__desc {
        font-size: .8rem;
        line-height: 165%;
        width: calc(50% - 18rem);
        padding: 1.7rem;
        background: #fff;
        color: #2F2F2F;
        border-radius: 1rem;
        font-weight: 700;
    }

    &-info {
        width: calc(50% - 21rem);
        margin-left: 3rem;
        background: #fff;
        color: #2F2F2F;
        border-radius: 1rem;
        font-weight: 700;
        font-size: 1rem;
        padding: 1.7rem;

        &__item {
            display: flex;
            margin-bottom: 0.25rem;

            &:last-child {
                margin-bottom: 0;
            }

            div {
                width: 50%;
                display: flex;

                span {
                    display: inline-block;
                    width: 50%;
                }
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1.5rem;
        position: relative;
        z-index: 3;
    }

    &__img {
        width: 33rem;
        height: 33rem;
        object-fit: contain;
        z-index: 3;
    }

    &-bg {
        background-image: '';
        height: 50vh;
        background-repeat: repeat;
        background-size: 100% auto;
        animation: animatedBackground 400s linear infinite;
        background-position: center center;
        position: relative;
        z-index: 2;
        margin-top: -15rem;
    }
}


@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -10000px;
  }
}

@media (max-width: 1500px) {
    .product-info {
        width: calc(50% - 18rem);
        margin-left: 0;
    }
}
 
@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .product {
        height: auto;
        padding-bottom: 2rem;
        margin-bottom: 0;


        &__title {
            font-size: 2.2rem;
        }

        &__img {
            width: 20rem;
            height: auto;
            order: 1;
            margin: 0 auto;
        }

        &-content {
            flex-direction: column;
        }

        &__desc {
            order: 2;
            width: 100%;
            font-size: 0.9rem;
        }

        &-info {
            width: 30rem;
            order: 3;
        }

        &-bg {
            margin-top: 0;
            height: 40vh;
            background-size: 160vw auto;
        }

        @keyframes animatedBackground {
            from {
                background-position: -30vw 0;
            }
            to {
                background-position: -30vw -10000px;
            }
        }
    }
}

@media (max-width: 768px) {
    .product-info, .product__title {
        width: 100%;
    }
}

</style>

<style scoped>
.product::before {
    content: none;
}

.product::after {
    top: 5rem;
    bottom: auto;
    transform: translate(50%, -50%);
}

@media (max-width: 768px) {
    .product {
        overflow: visible;
    }

    .product::after {
        content: none;
    }
}
</style>