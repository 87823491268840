<template>
    <section class="main-news">
        <div class="main-news__title">
            {{$t('news.title')}}
        </div>
        <p class="main-news__desc" v-if="$route.path == '/'">
            {{$t('news.desc')}}
        </p>
        <p class="main-news__desc" v-else>
            {{$t('news.other')}}
        </p>
        <div class="main-news__arrows">
            <span @click="prevSlide">
                <arrow-left />
            </span>
            <span @click="nextSlide">
                <arrow-right />
            </span>
        </div>
        <div v-if="!this.$route.params.id">
            <swiper ref="swiperNews" :options="newsOptions" class="main-news__carousel wow fadeInUp" 
            data-wow-delay=".5s" v-if="news.length>0">
                <swiper-slide v-for="(item, idx) in news" :key="idx">
                    <news-item :image=item.photo :date=item.created_at :text=item.name_ru :id=item.id />
                </swiper-slide>
            </swiper>
        </div>
        <div v-else>
            <swiper ref="swiperNews" :options="newsOptions" class="main-news__carousel" v-if="news.length>0">
                <swiper-slide v-for="(item, idx) in filteredNews" :key="idx">
                    <news-item :image=item.photo :date=item.created_at :text=item.name_ru :id=item.id />
                </swiper-slide>
            </swiper>
        </div>
        <div class="main-news__btn">
            <router-link to="/news" class="btn">
                {{$t('news.btn')}}
            </router-link>
        </div>
    </section>
</template>

<script>
import NewsItem from './NewsItem.vue'
import ArrowLeft from './ArrowLeft.vue'
import ArrowRight from './ArrowRight.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
    components: {
        NewsItem,
        Swiper, SwiperSlide,
        ArrowLeft, ArrowRight
    },


    data() {
        return {
            image: 'news.jpg',
            date: '12.08.2021',
            text: 'TM Deya — пользуется признанием на рынке Узбекистана',
            id: 1,
            newsOptions: {
                speed: 500,
                loop: true,
                centeredSlides: true,
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: "1",
                    },

                    550: {
                        slidesPerView: "2",
                        speceBetween: 20,
                    },

                    900: {
                        spaceBetween: 30,
                        slidesPerView: "3",
                    },

                    1200: {
                        spaceBetween: 30,
                        slidesPerView: "4",
                    },
                }
            },
        }
    },

    methods: {
        prevSlide() {
            this.$refs.swiperNews.$swiper.slidePrev()
        },

        nextSlide() {
            this.$refs.swiperNews.$swiper.slideNext()
        },
    },

    computed: {
        news() {
            return this.$store.getters.getNews
        },

        filteredNews() {
            return this.news.filter(item => item.id != this.$route.params.id)
        }
    }
}
</script>

<style lang="less">
.main-news {
    background: #e5e5e5;
    min-height: 100vh;
    padding: 4rem 0 7.5rem;
    position: relative;
    overflow-x: hidden;


    &__title {
        text-align: center;
        font-size: 12rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        text-shadow: 0px .3rem 1rem rgba(120, 120, 120, 0.4);
    }

    &__desc {
        text-align: center;
        font-weight: 700;
        font-size: 1.4rem;
        margin-bottom: 1rem;
        position: relative;
    }

    &__arrows {
        position: absolute;
        right: 6rem;
        top: 18rem;

        span {
            cursor: pointer;
            user-select: none;

            svg {
                width: 3.2rem;
                height: 3.2rem;
                filter: drop-shadow(4px 7px 16px rgba(0, 0, 0, 0.25));
                
                .fill {
                    transition: .3s all;
                }

                &:hover {
                    .fill {
                        fill: transparent;
                    }
                }
            }

            &:first-child {
                margin-right: 1.2rem;
            }

        }
    }

    &__btn {
        text-align: center;
        margin-top: .5rem;
    }
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .main-news {
        padding: 3rem 1rem;

        &__title {
            font-size: 4rem;
        }

        &__arrows {
            display: none;
        }

    }
}
</style>

<style scoped>
.swiper-container {
    padding: 1.5rem 0;
}


</style>