<template>
    <div class="product-item">
        <img :src="$hostname + image" alt="product" class="product-item__img">
        <img :src="$hostname + hoverImage" alt="product" class="product-item__hover" v-if="hoverImage">
        <div class="product-item__info">
            <div class="product-item__wrap" :style="{backgroundColor: color?color:'#FFE185'}">
                <div class="product-item__name" :class="{'desc-empty': !id}">
                    {{name}}
                </div>
                <div class="product-item__desc">
                    {{desc}}
                </div>
                <router-link :to="{path: '/products/' + id}" class="product-item__link" v-if="id">
                    <img src="../assets/img/arrow.svg" alt="ico">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image: String,
        color: String,
        name: String,
        desc: String,
        hoverImage: String,
        id: Number,
    },
}
</script>

<style lang="less">
.product-item {
    display: inline-block;
    height: 22rem;
    user-select: none;
    position: relative;

    &__img {
        width: 11rem;
        height: 11rem;
        object-fit: contain;
        object-position: bottom;
        filter: drop-shadow(6px 12px 10px rgba(0, 0, 0, 0.25));
        transition: .4s all;
        position: relative;
        z-index: 2;
    }

    &__hover {
        position: absolute;
        width: 7rem;
        height: 4rem;
        object-fit: contain;
        object-position: bottom;
        right: 10%;
        bottom: 30%;
        transition: .3s all;
        opacity: 0;
    }

    &__info {
        transition: .6s all;
        background: #F2F2F2;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        margin: -4.5rem 0 0 .5rem;
        overflow: hidden;
        position: relative;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
    }

    &__name {
        font-size: .9rem;
        margin-bottom: 0.5rem;
        font-weight: 700;
    }

    .desc-empty {
        min-height: 2.2rem;
        font-size: 1.3rem;
    }

    &__wrap {
        transition: .4s all;
        height: 12.5rem;
        width: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 1rem 0.5rem;
    }

    &__desc {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 114%;
        margin-bottom: 1rem;
        min-height: 1rem;
    }

    &__link {
        display: inline-block;
        margin-bottom: 0rem;
    }

    &:hover {
        .product-item__img {
            width: 12.5rem;
            height: 12.5rem;
        }

        .product-item__hover {
            opacity: 1;
            right: -2.5rem;
            bottom: 16rem;
            z-index: 0;
            margin: 0;
        }

        .product-item__info {
            width: 12.5rem;
            height: 12.5rem;
            margin-left: 0;
            box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.25);
            padding: 0 1rem;
        }

        .product-item__wrap {
            opacity: 1;
        }
    }
}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .product-item {
        &__hover {
            opacity: 1;
            right: -3.5rem;
            bottom: 16rem;
            z-index: 0;
            margin: 0;
        }

        &__wrap {
            opacity: 1;
            width: 11rem;
            height: 11rem;
        }

        &__name {
            font-size: 0.75rem;
        }

        &__img {
            width: 11rem;
            height: 11rem;
        }

        &__info {
            width: 11rem;
            height: 11rem;
            margin-left: 0;
            box-shadow: none;
            padding: 0 .7rem;
            backface-visibility: visible;
            transform: none;
            margin-top: -3.5rem;
        }

        &__desc {
            min-height: .5rem;
            margin-bottom: 0.7rem;
        }

        .desc-empty {
            font-size: 1.1rem;
        }

        &:hover {
            .product-item__img {
                width: 11rem;
                height: 11rem;
            }

            .product-item__hover {
                right: -3.5rem;
            }

            .product-item__info {
                width: 11rem;
                height: 11rem;
                box-shadow: none;
            }

        }
    }
}
</style>