<template>
    <form action="#" class="partners-form" @submit.prevent="sendMessage">
        <div class="partners-form__wrap">
            <span>
                {{$t('form.partners.name')}}
            </span>
            <input type="text" required v-model="name">
        </div>
        <div class="partners-form__wrap">
            <span>
                {{$t('form.partners.surname')}}
            </span>
            <input type="text" required v-model="surname">
        </div>
        <div class="partners-form__wrap">
            <span>
                {{$t('form.partners.tel')}}
            </span>
            <input type="tel" required v-model="tel">
        </div>
        <div class="partners-form__wrap">
            <span>
                {{$t('form.partners.address')}}
            </span>
            <input type="text" required v-model="address">
        </div>
        <div class="partners-form__message">
            <span>
                {{$t('form.partners.message')}}
            </span>
            <textarea v-model="message"></textarea>
        </div>
        <div class="partners-form__btn" v-if="!sended">
            <button type="submit" class="btn" :disabled="btnDisabled"
            >{{$t('form.partners.send')}}</button>
        </div>
        <div class="partners-form__done" v-else>
            Ваша заявка отправлена
        </div>
    </form>
</template>

<script>
import {coopAPI} from '../api'

export default {
    data() {
        return {
            name: '',
            surname: '',
            tel: '',
            address: '',
            message: '',
            sended: false,
            btnDisabled: false,
        }
    },
    methods: {
       async sendMessage() {
           this.btnDisabled = true
           await coopAPI(this.name, this.surname, this.tel, this.address, this.message)
           .then(response => {
               if(response.status == 200 || response.status == 201) {
                    this.name = ''
                    this.surname = ''
                    this.tel = ''
                    this.address = ''
                    this.message = ''
                    this.sended = true
               }
           })
       } 
    }
}
</script>

<style lang="less">
.partners-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 58rem;
    padding: 0 1rem;
    margin: 2.7rem auto 0;

    &__wrap {
        width: 49%;
        margin-bottom: 1.6rem;
    }

    span {
        display: block;
        padding-left: 1.2rem;
        color: #845F00;
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }

    input {
        width: 100%;
        border: .1rem solid var(--red);
        border-radius: .8rem;
        padding: 1rem;
        outline: none;
        font-size: 1rem;

        &:focus {
            border-color: #845F00;
        }
    }

    &__message {
        width: 100%;
        margin-bottom: 2rem;

        textarea {
            width: 100%;
            border: .1rem solid var(--red);
            border-radius: .8rem;
            padding: 1rem;
            outline: none;
            font-size: 1rem;
            resize: none;
            height: 12rem;
        }
    }

    &__btn {
        text-align: right;
        width: 100%;

        .btn {
            width: 22.5rem;
        }
    }

    &__done {
        width: 100%;
        text-align: right;
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--red);
    }
}


@media (max-width: 767px), (max-width: 1080px) and (orientation: portrait) {
    .partners-form__wrap {
        width: 100%;
    }
}
</style>