import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uz from './lang/uz'
import ru from './lang/ru'
import en from './lang/en'



Vue.use(VueI18n)

const messages = {
    uz,
    ru,
    en
}

  
export const i18n = new VueI18n({
  lang: ru,
  fallbackLocale: ru,
  messages 
})

