<template>
    <section class="partners pattern">
        <h3 class="partners__title">
            {{$t('partners.how')}}
        </h3>
        <div class="partners__text">
            {{$t('partners.info')}}
        </div>
        <h3 class="partners__title">
            {{$t('partners.trust')}}
        </h3>
        <swiper ref="swiperPartners" :options="partnersOptions" class="partners-carousel" v-if="partners.length>0" 
            @mouseenter.native="autoplay = false" @mouseleave.native="autoplay = true"> 
            <swiper-slide v-for="(item, idx) in partners" :key="idx">
                <div class="partners-carousel__item">
                    <img :src="$hostname + item.photo" alt="Logo">
                    <span></span>
                    <a :href="item.link" target="_blank"></a>
                </div>
            </swiper-slide>
        </swiper>
        <div class="partners-arrows" v-if="partners.length>0">
            <span class="partners-arrows__left" @click="prevSlide">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.4173 7.91781L3.17815 7.91781L7.65148 12.3911C8.00898 12.7486 8.00898 13.3353 7.65148 13.6928C7.29398 14.0503 6.71648 14.0503 6.35898 13.6928L0.318151 7.65198C-0.0393495 7.29448 -0.0393495 6.71698 0.31815 6.35948L6.35898 0.318646C6.71648 -0.0388538 7.29398 -0.0388538 7.65148 0.318646C8.00898 0.676146 8.00898 1.25365 7.65148 1.61115L3.17815 6.08448L13.4173 6.08448C13.9215 6.08448 14.334 6.49698 14.334 7.00115C14.334 7.50531 13.9215 7.91781 13.4173 7.91781Z" fill="currentColor"/>
                </svg>
            </span>
            <span class="partners-arrows__right" @click="nextSlide">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.58268 7.91781L11.8218 7.91781L7.34852 12.3911C6.99102 12.7486 6.99102 13.3353 7.34852 13.6928C7.70602 14.0503 8.28352 14.0503 8.64102 13.6928L14.6818 7.65198C15.0393 7.29448 15.0393 6.71698 14.6818 6.35948L8.64102 0.318646C8.28352 -0.0388538 7.70602 -0.0388538 7.34852 0.318646C6.99102 0.676146 6.99102 1.25365 7.34852 1.61115L11.8218 6.08448L1.58268 6.08448C1.07852 6.08448 0.666016 6.49698 0.666016 7.00115C0.666016 7.50531 1.07852 7.91781 1.58268 7.91781Z" fill="currentColor"/>
                </svg>
            </span>
        </div>
        <h3 class="partners__title">
            {{$t('partners.form')}}
        </h3>
        <partners-form />
        <div class="about-sweets">
            <img src="../assets/img/waffles.png" alt="Cookies" class="about-sweets__cookies" 
            v-rellax="sweets">
            <img src="../assets/img/waffles.png" alt="Waffles" class="about-sweets__waffles" 
            v-rellax="sweets">
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import {partnersAPI} from '../api'
import PartnersForm from '../components/PartnersForm.vue'

export default {
    components: {
        Swiper, SwiperSlide, PartnersForm
    },
    created() {
        partnersAPI().then(response => {
            this.partners = response.data.partners
            setInterval(() => {
                if(this.autoplay && this.$refs.swiperPartners) {
                    this.$refs.swiperPartners.$swiper.slideNext()
                }		
            }, 1500)
        })
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.partners.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.partners.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.partners.keywords')
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    methods: {
        prevSlide() {
            this.$refs.swiperPartners.$swiper.slidePrev()
            this.autoplay = false
        },

        nextSlide() {
            this.$refs.swiperPartners.$swiper.slideNext()
            this.autoplay = false
        },
    },
    data() {
        return {
            sweets: {
                speed: 4
            },
            autoplay: true,
            partners: [],
            partnersOptions: {
                speed: 1100,
                slidesPerView: "auto",
                loop: true,
            }
        }
    }
}
</script>

<style lang="less">
.partners {
    padding: 11rem 0 8rem;
    background: #fffdf9;

    &__title {
        font-weight: 600;
        font-size: 2.4rem;
        text-align: center;
    }

    &__text {
        max-width: 42rem;
        padding: 0 1rem;
        font-size: 1.2rem;
        line-height: 167%;
        color: #545454;
        margin: 1.5rem auto 3rem;
        text-align: center;
    }

    &-carousel {
        margin: 2rem 0 1rem;


        &__item {
            position: relative;
            
            img {
                width: 100%;
                max-height: 10rem;
                object-fit: contain;
                position: relative;
                z-index: 2;
            }

            span {
                width: 7rem;
                height: 7rem;
                border-radius: 50%;
                display: block;
                background: var(--red);
                opacity: .4;
                margin: -3rem auto 0;
                transition: .3s all;
                transform: scale(0);
                transform-origin: center top;
            }

            &:hover span {
                transform: none;
            }

            a {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
            }
        }
    }

    &-arrows {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;

        span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            margin: 0 0.75rem;
            border: 1px solid #C2C2C2;
            color: #C2C2C2;
            border-radius: 50%;
            cursor: pointer;

            svg {
                width: .75rem;
            }

            &:hover {
                background: var(--red);
                color: #fff;
            }
        }
    }


    .about-sweets__cookies {
        bottom: 30vh;
        left: -5rem;
    }

    .about-sweets__waffles {
        width: 20rem;
    }
}

@media (max-width: 900px) {
    .partners  .about-sweets img{
        width: 10rem;
    }
}

@media (max-width: 767px), (max-width: 1080px) and (orientation: portrait) {
    .partners {
        padding: 8rem 0 4rem;

        &__title {
            font-size: 1.8rem;
        }


        &-form {

            &__wrap {
                width: 100%;
            }
        }
    }
}
</style>

<style scoped>
.pattern::before, .pattern::after {
    background-image: url(../assets/img/pattern-gray.png);
    background-size: contain;
}

.swiper-slide {
    width: 9rem;
    margin: 0 1.5rem;
}

</style>
