<template>
  <div>
    <about-page />
    <app-history />
    <app-gallery />
  </div>
</template>

<script>
import AppHistory from '../components/History.vue'
import AppGallery from '../components/Gallery.vue'
import AboutPage from '../components/AboutPage.vue'

export default {
  components: {
    AppHistory,
    AppGallery,
    AboutPage
  },
  mounted() {
    this.$options.metaInfo.title = this.$i18n.t('meta.about.title')
    this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.about.description')
    this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.about.keywords')
  },
  metaInfo: {
    title: '',
    meta: [
      { name: 'description', content: '' },
      { name: 'keywords', content: '' },
    ]
  },
}
</script>
