<template>
    <section class="package pattern">
        <div class="package-catalog">
            <h3 class="package-catalog__title">
                {{$t('catalog.title')}}
            </h3>
            <div class="package-catalog__carousel" v-if="filteredProducts.length>0">
                <div class="container">
                    <swiper ref="swiperProduct" :options="swiperOptions">
                        <swiper-slide v-for="(item,idx) in filteredProducts" :key="idx">
                            <product-item :image=item.photo :color=item.color :hoverImage=item.hover_photo
                            :name="getLangName(item)"  :id=item.id :desc="getLangDesc(item)"/>
                        </swiper-slide>
                    </swiper>
                    <div class="arrow-left" @click="productPrev">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.9166 15.5834L11.0925 15.5834L18.0058 8.67006C18.5583 8.11756 18.5583 7.21089 18.0058 6.65839C17.4533 6.10589 16.5608 6.10589 16.0083 6.65839L6.67248 15.9942C6.11998 16.5467 6.11998 17.4392 6.67248 17.9917L16.0083 27.3276C16.5608 27.8801 17.4533 27.8801 18.0058 27.3276C18.5583 26.7751 18.5583 25.8826 18.0058 25.3301L11.0925 18.4167L26.9166 18.4167C27.6958 18.4167 28.3333 17.7792 28.3333 17.0001C28.3333 16.2209 27.6958 15.5834 26.9166 15.5834Z" fill="currentColor"/>
                        </svg>
                    </div>
                    <div class="arrow-right" @click="productNext">
                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.08335 18.4167L22.9075 18.4167L15.9942 25.33C15.4417 25.8825 15.4417 26.7892 15.9942 27.3417C16.5467 27.8942 17.4392 27.8942 17.9917 27.3417L27.3275 18.0058C27.88 17.4533 27.88 16.5608 27.3275 16.0083L17.9917 6.6725C17.4392 6.12 16.5467 6.12 15.9942 6.6725C15.4417 7.225 15.4417 8.1175 15.9942 8.67L22.9075 15.5833L7.08335 15.5833C6.30419 15.5833 5.66669 16.2208 5.66669 17C5.66669 17.7792 6.30419 18.4167 7.08335 18.4167Z" fill="currentColor"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import ProductItem from './ProductItem.vue'

export default {
    components: {
        Swiper,		  
		SwiperSlide,
        ProductItem,
    },
    props: {
        comp: String,
        packageImg: String,
        packageInfo: Object,
        packageIcos: Array,
        product: Number,
        brand: Number
    },
    created() {
        this.host = window.location.host
    },  
    data() {
        return {
            sweet: {
                speed: 4
            },
            image: 'miller.png',
            color: '#D4A378',
            name: 'Miller Captain',
            desc: 'конфеты вафельнные',
            link: true,
            host: '',
            id: 1,
            swiperOptions: {
                autoWidth: true,
                slidesPerView: "auto",
                freeMode: true,
                speed: 500,
                allowTouchMove: false,
                breakpoints: {
                    0: {
                        spaceBetween: 20,
                        slidesPerView: "2",
                    },

                    550: {
                        slidesPerView: "3",
                        speceBetween: 20,
                    },

                    700: {
                        spaceBetween: 30,
                        slidesPerView: "4",
                    },

                    900: {
                        spaceBetween: 30,
                        slidesPerView: "5",
                    },

                    1200: {
                        spaceBetween: 30,
                        slidesPerView: "6",
                    },
                }
            },
        }
    },
    methods: {
       productNext() {
           this.$refs.swiperProduct.$swiper.slideNext()
       },
       productPrev() {
           this.$refs.swiperProduct.$swiper.slidePrev()
       },
       openFeedback() {
            this.$emit('openFeedback')
        },
        getLangDesc(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.l_name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.l_name_en
            } else {
                return item.l_name_ru
            }
        },
        getLangName(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.name_en
            } else {
                return item.name_ru
            }
        },
    },
    computed: {
        products() {
            return this.$store.getters.getProducts
        },

        filteredProducts() {
            let arr = this.products.filter(item => (item.cat_id === this.product && item.id != this.$route.params.id) )
            arr.sort((a, b) => {
                if (a.brand_id === this.brand && b.brand_id !== this.brand) {
                    return -1
                } else if (a.brand_id !== this.brand && b.brand_id === this.brand) {
                    return 1
                } else {
                    return 0
                }
            })
            return arr
        }
    },
}
</script>

<style lang="less">
.package {
    padding: 5rem 0;
    background: #fffdf6;
    position: relative;

    &-sweets {

        img {
            position: absolute;
            width: 15rem;

            &:first-child {
                left: 1rem;
                top: 45vh;
            }

            &:nth-child(2) {
                right: 1rem;
                top: 80vh;
            }
        }
    }

    &-container {
        max-width: 50rem;
        margin: 0 auto;
        padding: 0 1rem;
    }

    &__title {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 1.1rem;
    }

    &__comp {
        font-size: 1.1rem;
        line-height: 218%;
        text-align: justify;
        margin-bottom: 6rem;
    }

    &-wrap {
        display: flex;
        margin-bottom: 4rem;

        &>div {
            width: 40%;
        }

        &>div:last-child {
            width: 60%;
        }

        .package__title {
            margin-bottom: 2.2rem;
        }
    }

    &-details {
        border-right: 1px solid #E8E8E8;
        margin-bottom: 1rem;

        ul {
            li {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 0;
                }

                &>div {
                    display: flex;
                    align-items: center;
                }

                img {
                    width: 2rem;
                    margin-right: 1.2rem;
                }

                span {
                    font-size: 1.1rem;
                    font-weight: 600;
                }
            }
        }
    }

    &-form {
        padding-left: 3rem;
        position: relative;

        &>img {
            width: 100%;
            margin-bottom: 1rem;
            object-fit: contain;
        }
    }

    &-btns {
        text-align: center;

        .btn-white {
            margin-left: 1rem;
            border: .1rem solid var(--yellow);
            background: #000;
            background: transparent;
        }
    }

    &-catalog {

        &__title {
            font-weight: 600;
            font-size: 2.4rem;
            margin-bottom: 3.2rem;
            text-align: center;
        }
            
        &__carousel {
            position: relative;

            .arrow-left, .arrow-right {
                cursor: pointer;
                position: absolute;
                width: 4.8rem;
                height: 4.8rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 1px solid #A7A7A7;
                color: #A7A7A7;
                transition: .3s all;
                top: 50%;
                transform: translateY(-50%);
                z-index: 10;
                user-select: none;

                &:hover {
                    background: #fff;
                    border: 2px solid #c98801;
                    color: #c98801;
                }
            }

            .arrow-left {
                left: 3.5rem;
            }

            .arrow-right {
                right: 3.5rem;
            }

        }
    }
}


@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .package {
        padding: 0;

        &-sweets {
            display: none;
        }

        &__comp {
            margin-bottom: 3rem;
            text-align: left;
        }

        &-wrap {
            display: block;

            &>div, &>div:last-child {
                width: 100%;
            }
        }

        &-details {
            border-right: none;
            margin-bottom: 3rem;
        }

        &-form {
            padding-left: 0;

            &>img {
                width: 100%;
            }
        }

        &-btns {

            .btn-white {
                margin-left: 0;
                margin-top: 1rem;
            }
        }

        &-catalog {
            display: none;
        }
    }
}
</style>

<style scoped>
.pattern::before, .pattern::after {
    background-image: url(../assets/img/pattern-gray.png);
    background-size: contain;
}

</style>
