<template>
    <div>
        <page-video @changeHeader=changeHeader :notMain=true 
        :title="$t('catalog.title')" :text="$t('catalog.text')" :productPage="true"/>

        <section class="catalog pattern">
            <h2 class="catalog__title">
                {{$t('catalog.bigTitle')}}
            </h2>
            <div class="container">
                <div class="catalog-filter">
                    <div class="catalog-filter__item" v-for="(item, idx) in categories" 
                    :key="idx" @click="filterId = item.id" :class="{'catalog-filter__item-current': filterId == item.id}">
                        {{getLangName(item)}}
                    </div>
                </div>
                <div class="catalog-filter catalog-filter__brands" v-if="filteredBrands.length>0">
                    <div class="catalog-filter__item" v-for="(item, idx) in filteredBrands" 
                    :key="idx" @click="scrollToId(idx)">
                        {{getLangName(item)}}
                    </div>
                </div>
                <div class="catalog-item" v-for="(item, idx) in filteredBrands" :key="idx" :id="'item'+idx">
                    <h3 class="catalog-item__title">
                       {{getLangName(item)}}
                    </h3>
                    <div class="catalog-item__wrap">
                        <div class="catalog-item__product" v-for="(item,idx) in filteredProducts(item.id)" :key="idx">
                            <product-item :image=item.photo :color=item.color  :hoverImage=item.hover_photo
                            :name="getLangName(item)" :id=item.id :desc="getLangDesc(item)"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import PageVideo from '../components/PageVideo.vue'
import ProductItem from '../components/ProductItem.vue'

export default {
    components: {
        PageVideo, ProductItem
    },
    mounted() {
        this.$options.metaInfo.title = this.$i18n.t('meta.products.title')
        this.$options.metaInfo.meta[0].content = this.$i18n.t('meta.products.description')
        this.$options.metaInfo.meta[1].content = this.$i18n.t('meta.products.keywords')
    },
    metaInfo: {
        title: '',
        meta: [
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
        ]
    },
    data() {
        return {
            filterId: 1,
            scrolled: false,
        }
    },
    methods: {
        changeHeader(data) {
            this.$emit('changeHeader', data)
        },
        filteredProducts(id) {
            return this.products.filter(item => {
                if(item.cat_id === this.filterId && item.brand_id === id) {
                    return item
                }
            })
        },
        getLangName(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.name_en
            } else {
                return item.name_ru
            }
        },
        
        getLangDesc(item) {
            if(this.$root.$i18n.locale == 'uz') {
                return item.l_name_uz
            } else if (this.$root.$i18n.locale == 'en') {
                return item.l_name_en
            } else {
                return item.l_name_ru
            }
        },

        scrollToId(id) {
            let el = this.$el.querySelector(`#item${id}`);
            
            el.scrollIntoView({ behavior: 'smooth', block : 'nearest'});
            this.scrolled = true
        }
    },
    computed: {
        categories() {
            return this.$store.getters.getCategories
        },

        brands() {
            return this.$store.getters.getBrands
        },

        productsInit() {
            return this.$store.getters.getProducts
        },

        products() {
            let arr = this.productsInit.filter(item => item.cat_id == this.filterId)
            return arr.sort((a, b) => {
                let nameA, nameB
                if(this.$root.$i18n.locale == 'uz') {
                    nameA = a.name_uz || ''
                    nameB = b.name_uz || ''
                } else if (this.$root.$i18n.locale == 'en') {
                    nameA = a.name_en || ''
                    nameB = b.name_en || ''
                } else {
                    nameA = a.name_ru || ''
                    nameB = b.name_ru || ''
                }
                return nameA.localeCompare(nameB)
            });
        },

        videos() {
            return this.$store.getters.getVideos
        },

        filteredBrands() {
            return this.brands.filter(item => item.cat_id == this.filterId)
        },

    },
}
</script>

<style lang="less">
.catalog {
    padding: 3.4rem;
    background: #fffdf9;
    position: relative;


    &__title {
        color: #F7F6F3;
        font-size: 10rem;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        width: 100%;
        z-index: 0;
        margin-top: -5vh;
    }
    
    .container {
        z-index: 1;
        position: relative;
    }

    .scrolled {
        transform: translateY(12.5rem);
    }

    &-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        gap: .8rem;

        &__item {
            padding: 0.8rem 1.6rem;
            border-radius: 2rem;
            border: 1px solid var(--yellow);
            cursor: pointer;
            user-select: none;
            background: #f3f3f3;

            &:hover {
                background: var(--yellow);
            }

            &-current {
                background: var(--yellow);
            }
        }

        &__brands {
            margin: 3rem 0;
            position: relative;

            &::before {
                content: '';
                height: 1px;
                width: 80%;
                left: 10%;
                background: #DEDEDE;
                position: absolute;
                top: -1.5rem;
            }
        }
    }

    &-item {
        h3 {
            font-size: 1.8rem;
            font-weight: bold;
            background: -webkit-linear-gradient(0deg, #BD9E3E, #FFE185);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-bottom: 0.5rem;
            border-bottom: .1rem solid #E9E9E9;
            margin-bottom: 2.2rem;
        }

        &__wrap {
            display: flex;
            flex-wrap: wrap;
            
        }

        &__product {
            width: 19%;
            margin: 1% 0.5%;
            display: flex;
            justify-content: center;
        }
    }

}

@media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
    .catalog {
        padding: 3.4rem 0;

        &::before, &::after {
            display: none !important;
        }

        &__title {
            font-size: 3rem;
            left: 0;
        }

        &-filter {
            max-width: 100%;
            overflow: auto;
            justify-content: start;
            margin-bottom: 1.5rem;

            &__item {
                flex-shrink: 0;
            }

            &__brands {
                border-top: 1px solid #DEDEDE;
                margin-top: 1.5rem;
                padding-top: 1.5rem;
            }
        }

        &-item {

            &__wrap {
                max-width: 100%;
                overflow: auto;
                flex-wrap: nowrap;
            }

            &__product {
                width: 12rem;
                margin-right: 2rem;
            }
        }
    }
}
</style>

<style scoped>
    .pattern::after, .pattern::before {
        background: url(../assets/img/pattern-gray.png) center center no-repeat;
        background-size: contain;
    }

    .pattern::after {
        bottom: 30vh;
    }

    .pattern::before {
        top: 100vh;
    }
</style>