<template>
    <section class="aboutpage">
        <h2 class="about-page__title">
            {{$t("about.pageTitle")}}
        </h2>
        <Waffles />
    </section>
</template>

<script>
import Waffles from './Waffles.vue';

export default {
    components: {
        Waffles
    }
}
</script>

<style lang="less">
.aboutpage {
    background: #e5e5e5;
    padding-top: 7rem;

    .gallery-carousel__arrows {
        margin-bottom: 0;
    }
}
</style>