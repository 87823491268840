import * as axios from 'axios'


const instance = axios.create({
    baseURL: 'https://admin.deya.uz/api/',
    withCreadentials: true,
    headers: {
        'Content-Type': 'application/json',
      },
})

export const videosAPI = () => {
    return instance.get('videos')
}

export const historyAPI = () => {
    return instance.get('about')
}

export const newsAPI = () => {
    return instance.get('reports')
}

export const catalogAPI = () => {
    return instance.get('catalog')
}

export const partnersAPI = () => {
    return instance.get('contacts')
}

export const feedbackAPI = (name, surname, phone, country, IP, address, message, productId) => {
    return instance.get(`messages/create?name=${name}&surname=${surname}&phone=${phone}&country=${country}&ip=${IP}&address=${address}&message=${message}&product_id=${productId}`)
}

export const coopAPI = (name, surname, phone, address, message) => {
    return instance.get(`cooperations/create?name=${name}&surname=${surname}&number=${phone}&address=${address}&message=${message}`)
}




