<template>
    <section class="page-video" :class="{'page-video__other': notMain}">
        <div class="container">
            <div class="page-video__content">
                <h1 class="page-video__title">
                    {{title}}
                </h1>
                <div class="page-video__text">
                    {{text}}
                </div>
                <div class="page-video__btns" v-if="!notMain">
                    <router-link to="/about" class="btn">
                        {{$t('buttons.learnMore')}}
                    </router-link>
                    <router-link to="/products" class="btn btn-trans">
                        {{$t('main.catalog')}}
                    </router-link>
                </div>
                <a href="#" class="btn-trans btn" v-else-if="this.$route.path == '/news' " 
                :class="{none: notVideo}" @click.prevent="openFeedback">
                    {{$t('buttons.contact')}}
                </a>
                <div class="page-video__catalog" v-else >
                    <a :href="'/catalog/catalog-bonu.pdf'" download class="btn-trans btn" 
                    :class="{none: notVideo}" target="_blank">
                    {{$t('buttons.downCat')}} - "Bonu"
                    </a>
                    <a :href="'/catalog/catalog-iruskon.pdf'" download class="btn-trans btn" 
                    :class="{none: notVideo}" target="_blank">
                    {{$t('buttons.downCat')}} - "Iruskon"
                    </a>
                </div>
                
            </div>
        </div>
        <img src="../assets/img/scroll.svg" alt="ico" class="page-video__scroll">


        <div class="page-video__img"  v-if="notVideo">
            <img :src="$hostname + image" alt="ico" >
        </div>
        <div class="page-video__img"  v-else-if="productPage">
            <img src="../assets/img/products.jpg" alt="ico" >
        </div>
        <div class="page-video__video"  v-else>
            <video pip="false" autoplay="" loop="" muted="" playsinline="" webkit-playsinline=""> 
				<source :src="$hostname + video" type="video/mp4">
					Ваш браузер не поддерживает HTML5 видео.
			</video>
        </div>
    </section>
</template>

<script>

export default ({
    props: {
        title: String,
        text: String,
        video: String,
        notMain: Boolean,
        notVideo: Boolean,
        image: String,
        productPage: Boolean,
    },
    beforeCreate () {
        setTimeout(() => {
            this.$emit('changeHeader', true)
        }, 1)
        
    },
    created() {
        window.addEventListener('scroll', this.changingHeader)
        this.host = window.location.host
    },
    data() {
        return {
            host: ''
        }
    },
    beforeDestroy() {
        this.$emit('changeHeader', false)
        window.removeEventListener('scroll', this.changingHeader)
    },
    methods: {
        changingHeader() {
            if(window.pageYOffset >= window.innerHeight/4) {
                this.$emit('changeHeader', false)
            } else {
                this.$emit('changeHeader', true)
            }
        },
        openFeedback() {
            this.$emit('openFeedback')
        }
    },

})
</script>


<style lang="less">
    .page-video {
        height: 100vh;
        background: #75431e;
        display: flex;
        align-items: center;
        position: relative;

        .container {
            position: relative;
            z-index: 1;
        }

        &__content {
            color: #fff;
            max-width: 38rem;
        }

        &__title {
            font-size: 3.6rem;
            line-height: 120%;
            margin-bottom: 1rem;
        }

        &__text {
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 150%;
            margin-bottom: 2.4rem;
        }

        .btn:hover {
           background: rgba(196, 196, 196, 0.1);
            backdrop-filter: blur(13px);
            color: #fff; 
        }

        .btn-trans {
            margin-left: 0.8rem;
            border: .15rem solid var(--red);
            background: rgba(196, 196, 196, 0.1);
            backdrop-filter: blur(13px);
            color: #fff;

            &:hover {
                background: var(--red);
            }
        }

        &__other {
            justify-content: center;

            .page-video__content {
                max-width: 50rem;
                text-align: center;
            }

            .btn-trans {
                margin-left: 0;
            }
        }

        &__scroll {
            position: absolute;
            bottom: 2.8rem;
            left: 50%;
            transform: translateX(-50%);
            width: 2.1rem;
            z-index: 1;
        }

        &__img, &__video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            pointer-events: none;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .5);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__video::after {
            background: rgba(0, 0, 0, .4);
        }

        &__catalog {
            display: flex;
            gap: 1rem;
            justify-content: center;

            .btn {
                width: 16rem;
            }
        }
    }


    @media (max-width: 768px), (max-width: 1080px) and (orientation: portrait) {
        .page-video {
            padding-top: 3rem;
            justify-content: center;

            &__content {
                width: 100%;
                text-align: center;
            }

            &__title {
                font-size: 2.1rem;
            }

            &__btns {

                .btn {
                    width: 100%;

                    &-trans {
                        margin-left: 0;
                        margin-top: 1rem;
                    }
                }
            }

            &__other {
                .page-video__text {
                    margin-bottom: 5rem;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .page-video__catalog {
            flex-direction: column;
            gap: .5rem;

            .btn {
                width: 100%;
            }
        }
    }
</style>

<style scoped>
    .none {
        display: none;
    }
</style>